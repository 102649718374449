(function() {
    'use strict';

    angular.module('ionicAdmin', [
        'ionic',
        'ionicAlert',
        'ionicConfirmation',
        'ionicLogin',
        'ionicOffline',
        'ionicRequest',
        'ionicSettings',
        'ionicUser',
        'uabDefaultVariable',
        'uabLocalStorage'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicAdmin').controller(
        'AdminController',
        AdminController
    );

    AdminController.$inject = [
        'AdminService',
        'DefaultVariableService',
        'LoginService',
        '$scope'
    ];

    function AdminController(
        AdminService,
        DefaultVariableService,
        LoginService,
        $scope
    ) {
        var AdminController = this;

        $scope.$watch(
            'defaultSection',
            function(defaultSection) {
                AdminController.shownSection = DefaultVariableService.get(
                    defaultSection,
                    'edit'
                );
            }
        );

        $scope.$watch(
            'showEdit',
            function(showEdit) {
                AdminController.showEdit = DefaultVariableService.get(
                    showEdit,
                    true
                );
            }
        );

        $scope.$watch(
            'showSettings',
            function(showSettings) {
                AdminController.showSettings = DefaultVariableService.get(
                    showSettings,
                    true
                );
            }
        );

        $scope.$watch(
            'showOffline',
            function(showOffline) {
                AdminController.showOffline = DefaultVariableService.get(
                    showOffline,
                    true
                );
            }
        );

        AdminController.isOnline = isOnline;
        function isOnline() {
            return AdminService.isOnline();
        }

        AdminController.loadSettingOptions = loadSettingOptions;
        function loadSettingOptions() {
            AdminService.getSettingOptions().then(
                function(settingOptions) {
                    if (settingOptions) {
                        AdminController.settingOptions = settingOptions;
                    }

                    return settingOptions;
                }
            );
        }

        AdminController.show = show;
        function show(section) {
            AdminController.shownSection = section;
        }

        AdminController.reset = reset;
        function reset() {
            AdminController.tagLabel = 'EXERCISE LEVEL';

            AdminController.tagOptions = {
                params: [
                    {
                        key:   'name%',
                        value: 'Level'
                    }
                ]
            };
        }

        AdminController.init = init;
        function init() {
            AdminController.reset();
            AdminController.loadSettingOptions();
        }

        LoginService.register(AdminController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicAdmin').directive('ionicAdmin', ionicAdmin);

    function ionicAdmin() {
        return {
            controller:   'AdminController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                showEdit:     '=',
                showSettings: '=',
                showOffline:  '=',
            },
            template:'<div class="animated fadeIn tabs-icon-top row"><button data-ng-if="ctrl.showEdit" class="col button accept-color" data-ng-click="ctrl.show(\'edit\')"><i class="icon ion-edit"></i> USER EDIT</button> <button data-ng-if="ctrl.showSettings" class="col button accept-color" data-ng-click="ctrl.show(\'settings\')"><i class="icon ion-gear-a"></i> SETTINGS</button> <button data-ng-if="ctrl.showOffline" class="col button accept-color" data-ng-click="ctrl.show(\'offline\')"><i class="icon ion-android-sync"></i> OFFLINE</button></div><div data-ng-switch="ctrl.shownSection"><div data-ng-switch-when="edit"><ionic-user-edit options="ctrl.tagOptions" edit-button-text="SAVE" editing-button-text="SAVING" on-edit-message="USER UPDATED. PLEASE REFRESH THE APP FOR THE CHANGES TO TAKE EFFECT." tag-label="EXERCISE LEVEL"></ionic-user-edit></div><div data-ng-switch-when="settings"><ionic-settings data-ng-if="ctrl.settingOptions" on-update-message="SETTINGS UPDATED. PLEASE REFRESH THE APP FOR THE CHANGES TO TAKE EFFECT." options="ctrl.settingOptions"></ionic-settings></div><div data-ng-switch-when="offline"><ionic-offline></ionic-offline><ionic-clear-cache-button data-ng-if="ctrl.isOnline()"></ionic-clear-cache-button></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicAdmin').service(
        'AdminService',
        AdminService
    );

    AdminService.$inject = [
        'LoginService',
        'OfflineService',
        'RequestManagementService'
    ];

    function AdminService(
        LoginService,
        OfflineService,
        RequestManagementService
    ) {
        var AdminService = this;

        AdminService.getSettingOptions = getSettingOptions;
        function getSettingOptions() {
            return LoginService.getActiveUser().then(
                function(activeUser) {
                    if (activeUser) {
                        var request = RequestManagementService.getRequest();

                        request = RequestManagementService.setParams(
                            request,
                            [
                                'user_id=' + activeUser.id
                            ]
                        );

                        return request;
                    }

                    return false;
                }
            );
        }

        AdminService.isOnline = isOnline;
        function isOnline() {
            return OfflineService.isOnline();
        }
        
        AdminService.reset = reset;
        function reset() {
            
        }
        
        AdminService.reset();

        return AdminService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicAdmin').controller(
        'ClearCacheButtonController',
        ClearCacheButtonController
    );

    ClearCacheButtonController.$inject = [
        'AlertService',
        'ClearCacheButtonService',
        'IonicConfirmationService',
        'LoginService'
    ];

    function ClearCacheButtonController(
        AlertService,
        ClearCacheButtonService,
        IonicConfirmationService,
        LoginService
    ) {
        var ClearCacheButtonController = this;

        ClearCacheButtonController.clear = clear;
        function clear() {
            ClearCacheButtonService.reset();
            ClearCacheButtonController.reset();
        }

        ClearCacheButtonController.clearCache = clearCache;
        function clearCache() {
            return IonicConfirmationService.createConfirmation(
                'ARE YOU SURE?',
                'CLEAR',
                'CANCEL'
            ).then(
                function(confirm) {
                    if (confirm) {
                        return ClearCacheButtonService.clearCache().then(
                            function() {
                                AlertService.addMessage('CACHE CLEARED.');

                                return true;
                            }
                        );
                    }

                    return false;
                }
            );
        }

        ClearCacheButtonController.reset = reset;
        function reset() {

        }

        ClearCacheButtonController.init = init;
        function init() {
            ClearCacheButtonController.reset();
        }

        LoginService.register(ClearCacheButtonController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicAdmin').directive(
        'ionicClearCacheButton',
        ionicClearCacheButton
    );

    function ionicClearCacheButton() {
        return {
            controller:   'ClearCacheButtonController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<button class="button button-block decline-button" data-ng-click="ctrl.clearCache()" data-ng-disabled="ctrl.isClearingCache">{{ ctrl.isClearingCache ? \'CLEARING\' : \'CLEAR DATA\' }}</button>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicAdmin').service(
        'ClearCacheButtonService',
        ClearCacheButtonService
    );

    ClearCacheButtonService.$inject = [
        'LocalStorageService',
        'LoginService',
        'OfflineService'
    ];

    function ClearCacheButtonService(
        LocalStorageService,
        LoginService,
        OfflineService
    ) {
        var ClearCacheButtonService = this;

        ClearCacheButtonService.clearCache = clearCache;
        function clearCache() {
            ClearCacheButtonService.isClearingCache = true;

            return OfflineService.clearAll().then(
                function() {
                    LocalStorageService.clearAll();

                    return LoginService.onLogoutSuccess();
                }
            ).finally(
                function() {
                    ClearCacheButtonService.isClearingCache = false;
                }
            );
        }
        
        ClearCacheButtonService.reset = reset;
        function reset() {
            
        }
        
        ClearCacheButtonService.reset();

        return ClearCacheButtonService;
    }
})();